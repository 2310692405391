import { withDependencies } from '@wix/thunderbolt-ioc'
import type { INavigationManager } from './types'
import { createPromise } from '@wix/thunderbolt-commons'

export const NavigationManager = withDependencies(
	[],
	(): INavigationManager => {
		let isDuringNavigation = false
		let _shouldBlockRender = false
		let isFirstNavigation = true
		let navigationEndResolvers: Array<() => void> = []
		let continueNavigationResolvers: Array<(isLast: boolean) => void> = []
		return {
			endNavigation: () => {
				isDuringNavigation = false
				isFirstNavigation = false
				continueNavigationResolvers.forEach((resolver, idx) =>
					resolver(idx === continueNavigationResolvers.length - 1)
				)
				continueNavigationResolvers = []
				navigationEndResolvers.forEach((resolver) => resolver())
				navigationEndResolvers = []
			},
			setShouldBlockRender: (shouldBlockRender: boolean) => {
				_shouldBlockRender = shouldBlockRender
			},
			isDuringNavigation: () => isDuringNavigation,
			shouldBlockRender: () => _shouldBlockRender,
			isFirstNavigation: () => isFirstNavigation,
			startNavigation: () => {
				isDuringNavigation = true
			},
			waitForShouldContinueNavigation: () => {
				const { resolver, promise } = createPromise<boolean>()
				continueNavigationResolvers.push(resolver)
				return promise
			},
			waitForNavigationEnd: () => {
				const { resolver, promise } = createPromise()
				navigationEndResolvers.push(resolver)
				return promise
			},
		}
	}
)
